/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../styles/sections/bronzecoin.css";
import { FiGithub } from "react-icons/fi";
import { LuCode2 } from "react-icons/lu";
import { LiaWindows } from "react-icons/lia";
import { TbDeviceDesktop } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import BronzeLogo  from '../../assets/images/logo.jpg'

const Bronzecoin = () => {
  return (
    <div className="bronze-contain">
      <div className="bronze-img bronze">
      <img src={BronzeLogo} style={{maxWidth: '100%', height: 'auto'}} alt="logo" className="" />
      </div>
      <div className="bronze-side bronze">
        <div className="bronze-relative">
          <h2 className="bronze-down">BronzeCoins Downloads</h2>
        </div>
        <div className="bronze-icons">
          <a href="#" className="bronze-links">
            <FiGithub className="bronze-icon" />
          </a>
          <a href="#" className="bronze-links">
            <LuCode2 className="bronze-icon" />
          </a>
          <a href="#" className="bronze-links">
            <LiaWindows className="bronze-icon" />
          </a>
          <a href="#" className="bronze-links">
            <TbDeviceDesktop className="bronze-icon" />
          </a>
          <a href="root" className="bronze-links" target="_blank">
            <IoSettingsOutline className="bronze-icon" />
          </a>
        </div>
        <h3 className="bronze-title">Revolution in Fair Mining</h3>
        <p className="bronze-text">
          Experience the forefront of decentralized mining with BronzeCoin. Our
          commitment to fairness and transparency sets a new standard in the
          world of cryptocurrency mining.
        </p>
        <div className="bronze-list">
          <div className="bronze-list-item">
            <div className="bronze-list-icon">
              <FaArrowRightLong className="bronze-arrow" />
            </div>
            <a href="#" className="bronze-list-link">
              Difficulty Adjustment from genesis block
            </a>
          </div>
          <div className="bronze-list-item">
            <div className="bronze-list-icon">
              <FaArrowRightLong className="bronze-arrow" />
            </div>
            <a href="#" className="bronze-list-link">
              Instamine resistant - ensuring fair mining.
            </a>
          </div>
          <div className="bronze-list-item">
            <div className="bronze-list-icon">
              <FaArrowRightLong className="bronze-arrow" />
            </div>
            <a href="#" className="bronze-list-link">
              No premine or presale.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bronzecoin;
