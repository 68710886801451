/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../styles/navbar/desktopNav.css";

const DesktopNavbar = () => {
  return (
    <div className="container-desktop">
      <div className="navbar-desk">
        <nav className="nav-desk">
          <ul className="list-desk">
            <li className="list-item">
              <a className="list-link active" href="#">
                Home
              </a>
            </li>
            <li className="list-item">
              <a className="list-link" href="#">
                Docs
              </a>
            </li>
            <li className="list-item">
              <a className="list-link" href="#">
                Emmission Schedule
              </a>
            </li>
            <li className="list-item">
              <a className="list-link" href="#">
                BlockFeed<span className="span">(live!)</span>
              </a>
            </li>
          </ul>
        </nav>
        <section className="sect">
          <a href="" className="button dashed">
            Explorer
          </a>
          <a href="#ecosystem" className="button solid">
            Ecosystem
          </a>
        </section>
      </div>
    </div>
  );
};

export default DesktopNavbar;
