import React from 'react';
import './App.css';
import useBreakpoint from './hooks/breakpoints';
import { Container } from './components/container';
import { MainLayout } from './components/layout';

function App() {

  const currentBreakpoint = useBreakpoint();
  console.log(currentBreakpoint);
  

  return (
    <div>
      <MainLayout>
        <Container />
      </MainLayout>
    </div>
  );
}

export default App;