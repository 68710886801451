import React from 'react';
import useBreakpoint from '../../hooks/breakpoints';
import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';
import '../styles/navbar/navbar.css';

export const Navbar = () => {
  const currentBreakpoint = useBreakpoint();

  return (
    <div className='container-custom'>
      {currentBreakpoint === 'sm' || currentBreakpoint === 'md' ? (
        <MobileNavbar />
      ) : (
        <DesktopNavbar />
      )}
    </div>
  );
};
