import { useState, useEffect } from 'react';

// Define the breakpoints
const breakpoints = {
  sm: '(max-width: 576px)',
  md: '(min-width: 577px) and (max-width: 768px)',
  lg: '(min-width: 769px)',
};

// Custom Hook: useBreakpoint
function useBreakpoint() {
  // State variable for the current breakpoint
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint);

  // Function to get the current breakpoint based on window width
  function getBreakpoint() {
    if (window.matchMedia(breakpoints.lg).matches) {
      return 'lg';
    } else if (window.matchMedia(breakpoints.md).matches) {
      return 'md';
    } else {
      return 'sm';
    }
  }

  // Effect to update the breakpoint on window resize
  useEffect(() => {
    function handleResize() {
      setCurrentBreakpoint(getBreakpoint());
    }

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Return the current breakpoint
  return currentBreakpoint;
}

export default useBreakpoint;
