import React, { useState } from "react";
import "../styles/navbar/mobileNav.css";
import { MdOutlineMenu, MdOutlineClose } from "react-icons/md";
import BronzeCoinIcon from "../../assets/images/logo-no-bg.png";

const MobileNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`container-mobile ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="nav-bar">
        <div className="nav-brand">
          <a href={"/"}>
            <img
              src={BronzeCoinIcon}
              alt="logo"
              className="nav-logo"
              style={{ maxWidth: "8%", height: "auto" }}
            />
          </a>
        </div>
        {isMenuOpen && (
          <nav className="nav">
            <ul className="list">
              <li className="list-items">
                <a className="list-link active" href="#">
                  Home
                </a>
              </li>
              <li className="list-items">
                <a className="list-link" href="#">
                  Docs
                </a>
              </li>
              <li className="list-items">
                <a className="list-link" href="#">
                  Emmission Schedule
                </a>
              </li>
              <li className="list-items">
                <a className="list-link" href="#">
                  BlockFeed<span className="span">(live!)</span>
                </a>
              </li>
            </ul>
            <section className="sect">
              <a href="" className="button dashed">
                Explorer
              </a>
              <a href="#ecosystem" className="button solid">
                Ecosystem
              </a>
            </section>
          </nav>
        )}
        <button onClick={toggleMenu} className="menu-icon-btn">
          {isMenuOpen ? (
            <MdOutlineClose className="menu-icon close-menu-icon" />
          ) : (
            <MdOutlineMenu className="menu-icon" />
          )}
        </button>
      </div>
    </div>
  );
};

export default MobileNavbar;
