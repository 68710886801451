import React from "react";
import "../styles/sections/decentral.css";

const Decentral = () => {
  return (
    <div className="decent-container">
      <div className="dec-contain">
        <div className="decent-col">
        <div className="decent-list decent-list-dark">
          <h2 className="decent-title-dark">Decentralization at Its Core</h2>
          <p className="decent-text decent-para">
            BronzeCoin places a premium on decentralization. The network relies
            on a distributed group of nodes to validate transactions and secure
            the blockchain. This commitment to decentralization reduces the risk
            of central control, making it more resilient and
            censorship-resistant.
          </p>
          <p className="decent-text decent-para">
            BronzeCoin' dedication to decentralization not only makes it more
            resilient but also enhances security and privacy. With a distributed
            network of nodes, the blockchain becomes less susceptible to single
            points of failure and malicious attacks. This architecture ensures
            that your transactions and data remain secure, and it significantly
            reduces the risk of censorship, providing you with a truly private
            and secure blockchain experience.
          </p>
        </div>
        </div>
        <div className="decent-col decent-aside">
          <div className="decent-list decent-aside-top">
            <h2 className="decent-title">Innovative Features</h2>
            <p className="decent-text">
              BronzeCoin continually strives to introduce innovative features
              and improvements to its platform. This commitment to innovation
              ensures that the project remains competitive and adaptable in the
              rapidly evolving blockchain space.
            </p>
          </div>
          <div className="decent-list">
            <h2 className="decent-title">Community Empowerment</h2>
            <p className="decent-text">
              At BronzeCoin, community members are at the forefront. Our
              community-driven philosophy ensures that decisions regarding
              platform upgrades, governance, and development are made
              collectively, fostering transparency and inclusivity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decentral;
