import React from "react";
import "../styles/sections/whyBronze.css";

const WhyBronze = () => {
  return (
    <div className="why-container">
      <h2 className="why-title">Why Choose <a href="#dashboard" className="why-link">BronzeCoin?</a></h2>
      <p className="why-text">
        BronzeCoin stands out in the world of blockchain and cryptocurrency for
        several compelling reasons. In a landscape filled with numerous digital
        assets and blockchain platforms, it's important to understand why
        BronzeCoin is a preferred choice for many users and investors.
      </p>
    </div>
  );
};

export default WhyBronze;
