import React from "react";
import Dashboard from "../sections/DashBoard";
import '../styles/container/container.css'
import Premine from "../sections/Premine";
import Bronzecoin from "../sections/Bronzecoin";
import Feature from "../sections/Feature";
import WhyBronze from "../sections/WhyBronze";
import Decentral from "../sections/Decentral";
import Technical from "../sections/Technical";
import Ecosystem from "../sections/Ecosystem";
import Roadmap from "../sections/Roadmap";
import Founder from "../sections/Founder";

export const Container = () => {
  return (
    <div>
      <Dashboard />
      <Premine />
      <Bronzecoin />
      <Feature />
      <WhyBronze />
      <Decentral />
      <Technical />
      <Ecosystem />
      <Roadmap />
      <Founder />
    </div>
  );
};
