import React from "react";
import "../styles/sections/technical.css";

const Technical = () => {
  return (
    <div className="technical-container">
      <div className="tech-contain">
        <h2 className="technical-title">Technical Specification</h2>
        <div className="technical-list">
          <div className="technical-item">
            <h3 className="technical-item-title">Block Rewards</h3>
            <p className="technical-item-text">
              The BronzeCoin mainnet kicks off with an initial miner reward of 9
              coins per block, and this reward is reduced with each subsequent
              reduction event.
            </p>
          </div>
          <div className="technical-item">
            <h3 className="technical-item-title">Rewards Reduction</h3>
            <p className="technical-item-text">
              The BronzeCoin block reward is reduced 5% approximately every
              200,000 blocks, roughly equivalent to a one-month interval.
            </p>
          </div>
          <div className="technical-item">
            <h3 className="technical-item-title">Project Funding</h3>
            <p className="technical-item-text">
              During the first 2,000,000 blocks, an additional 2 coins are
              emitted and allocated to the development treasury. After 2M block,
              this will be over.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technical;
