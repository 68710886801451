import React from "react";
import "../styles/sections/roadmap.css";

const Roadmap = () => {
  return (
    <div className="road-container">
      <div className="road-contain">
        <h2 className="road-title">Roadmap</h2>
        <div className="road-list">
          <div className="road-item">
            <h3 className="road-item-title">Phase - 1 (2023)</h3>
            <p className="road-item-text">
              Community Building, Mainnet Launch, Mining Pools, Block Explorer,
              Metamask RPC.
            </p>
          </div>
          <div className="road-item">
            <h3 className="road-item-title">Phase - 2 (2023)</h3>
            <p className="road-item-text">
              CEX & DEX Listings, Cross-chain Support, CMC & CG Listing, Social
              Media Development.
            </p>
          </div>
          <div className="road-item">
            <h3 className="road-item-title">Phase - 3 (2023)</h3>
            <p className="road-item-text">
              DeFi Protocols Development, security and product improvements,
              Next Roadmap Release.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
