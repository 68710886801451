import React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord, FaTwitter } from "react-icons/fa6";
import { TbBrandGithubFilled } from "react-icons/tb";
import "../styles/footer/mobileFooter.css";

const MobileFooter = () => {
  return (
    <div className="footer-container">
      <div className="footer-top">
        <section className="top-items">
          <h2 className="footer-top-title">BronzeCoin</h2>
          <div className="footer-icons">
            <a href="#" className="footer-icon">
              <FaTwitter />
            </a>
            <a href="#" className="footer-icon">
              <FaTelegramPlane />
            </a>
            <a href="#" className="footer-icon">
              <FaDiscord />
            </a>
            <a href="#" className="footer-icon">
              <TbBrandGithubFilled />
            </a>
          </div>
          <p className="footer-mails">mail@bronzecoin.com</p>
          <p className="footer-mails">bronzecoin@gmail.com</p>
        </section>
        <div className="footer-items">
          <section className="footer-item">
            <h2 className="footer-top-title">Mining</h2>
            <a href="#" className="footer-top-text">
              WhatToMine
            </a>
            <a href="#" className="footer-top-text">
              MiningPoolStats
            </a>
            <a href="#" className="footer-top-text">
              PoolBay
            </a>
          </section>
          <section className="footer-item footer-market">
            <h2 className="footer-top-title footer-market-title">Markets</h2>
            <a href="#" className="footer-top-text">
              Uniswap
            </a>
            <a href="#" className="footer-top-text">
              PancakeSwap
            </a>
            <a href="#" className="footer-top-text">
              SafeTrade
            </a>
            <a href="#" className="footer-top-text">
              XeggeX
            </a>
            <a href="#" className="footer-top-text">
              BitxOnex
            </a>
          </section>
          <section className="footer-item footer-info">
            <h2 className="footer-top-title footer-info-title">Information</h2>
            <a href="#" className="footer-top-text">
              CoinGecko
            </a>
            <a href="#" className="footer-top-text">
              CoinMarketCap
            </a>
            <a href="#" className="footer-top-text">
              Crypto.com
            </a>
            <a href="#" className="footer-top-text">
              FXEmpire
            </a>
          </section>
          <section className="footer-item footer-utils">
            <h2 className="footer-top-title footer-utils-title">Utilities</h2>
            <a href="#" className="footer-top-text">
              Block Explorer
            </a>
            <a href="#" className="footer-top-text">
              Logo Kit
            </a>
          </section>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-bottom-text">
          &copy; Copyright 2023 BronzeCoin - All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default MobileFooter;
