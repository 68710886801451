import React from "react";
import '../styles/sections/dashboard.css'
import { IoDocumentTextOutline, IoArrowForwardCircle } from "react-icons/io5";

const Dashboard = () => {
  return (
    <div className="main-contain" id="dashboard">
      <div className="dash-container">
        <div className="whitepaper">
          <IoDocumentTextOutline className="icon" />
          <p className="white-text">Whitepaper</p>
          <a className="white-link" href="#">
            <IoArrowForwardCircle className="icon" />
          </a>
        </div>
        <h1 className="h1-dash">Blocks Powered by Proof of Work...</h1>
        <p className="para-dash">
          BronzeCoin is an exclusive decentralized layer-1 proof-of-work
          blockchain meticulously designed to cater to the mining community.
          What sets BronzeCoin apart from all other Ethash-based cryptocurrencies
          is its unique emission model, characterized by a built-in system of
          reducing the block rewards by 5% every month. Additionally, BronzeCoin
          offers comprehensive support for smart contracts and compatibility
          with the Ethereum Virtual Machine (EVM), empowering developers to
          build decentralized applications (DApps) and automate various
          processes on the blockchain.
        </p>
        <div className="buttons">
          <a href="#premine" className="dash-dashed">Get Started</a>
          <a className="dash-dashed">BlockFeed</a>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
