import React from "react";
import "../styles/sections/feature.css";
import { LuCode2 } from "react-icons/lu";
import { PiTrendUp } from "react-icons/pi";
import { HiOutlineUsers } from "react-icons/hi2";

const Feature = () => {
  return (
    <div className="feature-container">
      <div className="feature-list">
        <h2 className="feature-list-title">Key Features of BronzeCoin</h2>
        <div className="feature-list-item">
          <div className="feature-icon-cont">
            <LuCode2 className="feature-icon" />
          </div>
          <div className="feature-icon-aside">
            <h4 className="feature-list-text">Smart Contracts</h4>
            <p className="feature-list-para">
              BronzeCoin enables the execution of smart contracts, allowing
              developers to build decentralized applications (DApps) and
              automate various processes on the blockchain.
            </p>
          </div>
        </div>
        <div className="feature-list-item">
          <div className="feature-icon-cont">
            <PiTrendUp className="feature-icon" />
          </div>
          <div className="feature-icon-aside">
            <h4 className="feature-list-text">Value-centered Tokenomics</h4>
            <p className="feature-list-para">
              BronzeCoin aims to make its coin more valuable by reducing the
              number of coins given out as block rewards each month. This
              reduction is 5% every 200,000 blocks (approximately every month).
            </p>
          </div>
        </div>
        <div className="feature-list-item">
          <div className="feature-icon-cont">
            <HiOutlineUsers className="feature-icon" />
          </div>
          <div className="feature-icon-aside">
            <h4 className="feature-list-text">Community Driven</h4>
            <p className="feature-list-para">
              Community Driven BronzeCoin places a strong emphasis on community
              involvement and decision-making. Miners and users have a
              significant say in the coin's development, governance, and future
              direction, making it a truly community-driven digital asset.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
