import React, { FC } from 'react'
import { Navbar } from '../navbar';
import { Footer } from '../footer';
import '../styles/container/container.css';

interface MainLayoutProps {
    children: React.ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className='container-width'>
        <Navbar />
        {children}
        <Footer />
    </div>
  )
}