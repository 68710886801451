import React from "react";
import "../styles/sections/ecosystem.css";

const Ecosystem = () => {
  return (
    <div className="eco-container" id="ecosystem">
      <div className="eco-contain">
        <h2 className="eco-title">Ecosystem</h2>
        <div className="eco-texts">
          <p className="eco-text-item">Circulating Supply: 5,633,244.73 XBC</p>
          <p className="eco-text-item">Max Supply: 40,000,000 XBC</p>
          <p className="eco-text-item">Current Price: $1.02</p>
          <p className="eco-text-item">Current Market Cap: $5,721,477.53</p>
          <p className="eco-text-item">
            Fully Diluted Market Cap: $40,626,514.96
          </p>
          <p className="eco-text-item eco-contract">
            XBC Contract Address (Ethereum / BSC):{" "}
            <span className="eco-span">
              0xcb129Aa11CEaA00DA1A92D12E26bB776eF3c3628
            </span>
          </p>
        </div>
        <div className="eco-button-list">
          <button className="eco-button-item">BlockFeed</button>
          <button className="eco-button-item">XBC Farm (ETH)</button>
          <button className="eco-button-item">Web Wallet</button>
          <button className="eco-button-item">Bridge</button>
          <button className="eco-button-item">DEX</button>
          <button className="eco-button-item">Faucet</button>
          <button className="eco-button-item">Metamask</button>
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
