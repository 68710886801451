import React from "react";
import "../styles/sections/premine.css";
import { HiOutlineXMark } from "react-icons/hi2";
import { LuShieldCheck } from "react-icons/lu";
import { IoExtensionPuzzleOutline } from "react-icons/io5";

const Premine = () => {
  return (
    <div className="pre-container" id="premine">
      <div className="pre-list-mobile">
        <div className="pre-list">
          <HiOutlineXMark className="pre-icon" />
          <h5 className="pre-title">No Premine</h5>
          <p className="pre-text">
            Fairness from the Start: No Premine on BronzeCoin.
          </p>
        </div>
        <div className="pre-list">
          <LuShieldCheck className="pre-icon" />
          <h5 className="pre-title">Instamine Resistant</h5>
          <p className="pre-text">
            Effective Difficulty Adjustments to Prevent Instant Mining and
            Forks.
          </p>
        </div>
      </div>
      <div className="pre-list pre-list-desk">
        <IoExtensionPuzzleOutline className="pre-icon" />
        <h5 className="pre-title">Block Reward Reduction</h5>
        <p className="pre-text">
          5% block reward reduction every 200,000 blocks.
        </p>
      </div>
    </div>
  );
};

export default Premine;
