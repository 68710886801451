import React from "react";
import useBreakpoint from "../../hooks/breakpoints";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import '../styles/footer/footer.css'

export const Footer = () => {
  const currentBreakpoint = useBreakpoint();

  return (
    <div className="footer">
      {currentBreakpoint === "sm" || currentBreakpoint === "md" ? (
        <MobileFooter />
      ) : (
        <DesktopFooter />
      )}
    </div>
  );
};
