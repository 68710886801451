import React from "react";
import "../styles/sections/founder.css";

const Founder = () => {
  return (
    <div className="found-contain">
      <div className="founder-container">
        <p className="founder-text">
          "BronzeCoin is not just a blockchain; it's a testament to innovation
          and community support. We're committed to building a secure,
          adaptable, and community-driven platform for the future of blockchain
          technology. Join us on this exciting journey towards a decentralized
          tomorrow."
        </p>
        <h2 className="founder">- Founder of BronzeCoin</h2>
      </div>
    </div>
  );
};

export default Founder;
